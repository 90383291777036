import { defineStore } from 'pinia'

import { apiClientDB } from '@/config_sessions'

export const useCameraStreamStore = defineStore({
    id: 'inputcamerastream',

    state: () => ({
        inputcamerastreams: [],
        inputcamerastream: {},
        loading: false,
        error: null
    }),

    getters: {

    },

    actions: {
        async fetchInputCameraStreams() {
            // // Initialize cameraStreamData in local storage if it doesn't exist
            // const storedCameraStreams = JSON.parse(localStorage.getItem('cameraStreamData')) || null

            // // Check if the camera streams are already cached in local storage
            // if (storedCameraStreams) {
            //     // Set inputcamerastreams from cached data
            //     this.inputcamerastreams = storedCameraStreams

            //     return this.inputcamerastreams
            // }

            // If not cached, fetch from API
            this.inputcamerastreams = []
            this.loading = true
            try {
                const response = await apiClientDB.get(`/camera_streams/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })
                // Set inputcamerastreams with fetched data
                this.inputcamerastreams = response.data.camerastreams

                // Cache the response data in local storage for future use
                // localStorage.setItem('cameraStreamData', JSON.stringify(this.inputcamerastreams))

                return this.inputcamerastreams
            }
            catch (error) {
                this.error = error
            }
            finally {
                this.loading = false
            }
        },

        async fetchInputCameraStreambyFlowId(FlowId) {
            this.inputcamerastream = {}
            this.loading = true
            try {
                const response = await apiClientDB.get(`/camera_streams/flow/${FlowId}/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })
                this.inputcamerastream = response.data
            }
            catch (error) {
                if (error.response) {
                    alert("There was an error processing your request. You will be redirected to the homepage.")
                    setTimeout(() => {
                        window.location = '/'
                    }, 3000)
                } 
                else {
                    this.error = error
                }
            }
            finally {
                this.loading = false
            }
        },

        async fetchInputCameraStream(CameraStreamId) {
            this.inputcamerastream = {}
            this.loading = true
            try {
                const response = await apiClientDB.get(`/camera_streams/${CameraStreamId}/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })
                this.inputcamerastream = response.data
            }
            catch (error) {
                this.error = error
            }
            finally {
                this.loading = false
            }
        },

        async addInputCameraStreamToFlow(FlowId, StreamLink) {
            try {
                const data = {
                    FlowId,
                    StreamLink,
                }

                await apiClientDB.post(`/camera_streams/`, data, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })

                await this.fetchInputCameraStreams()
            }
            catch (error) {
                this.error = error
            }
            finally {
                this.loading = false
            }
        },

        async editInputCameraStream(CameraStreamId, StreamLink, FlowId, StreamStatus) {
            try {
                const data = {
                    CameraStreamId,
                    StreamLink,
                    FlowId,
                    StreamStatus,
                }

                await apiClientDB.put(`/camera_streams/${CameraStreamId}/`, data, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })

                await this.fetchInputCameraStream(CameraStreamId)
            }
            catch (error) {
                this.error = error
            }
            finally {
                this.loading = false
            }
        },

        clearStreams() {
            this.inputcamerastreams = []
            localStorage.removeItem('cameraStreamData')  // Clear from localStorage
        }
    }
})