import { apiClientEVENTS } from '@/config_sessions'

import { useToast } from 'vue-toastification'
const toast = useToast()


const authHeader = () => ({
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    }
})

const handleAxiosError = (error) => {
    if (error.response) {
        const { status, data } = error.response
        const errorMessage = data.message || data.error || 'Unknown error'

        if (status === 401 && errorMessage.includes('Token')) {
            console.error('Token expired. Redirecting to login.')
            toast.error('Token expired. Please log in again.')
            // Redirect to login page
            window.location.href = '/login'
        } else {
            console.error('Server Error:', errorMessage)
            toast.error(`Server Error: ${errorMessage}`)
        }
    } else if (error.request) {
        console.error('Network Error:', error.request)
        toast.error('Network Error. Please try again later.')
    } else {
        console.error('Error:', error.message)
        toast.error(`Error: ${error.message}`)
    }
}

// Function to format the date and get the timezone offset
const formatDateWithOffset = (date) => {
    // Convert local machine date to UTC time zone
    const dateString = date.toISOString()
    
    // Get the offset in minutes (e.g., -120 for GMT+0200)
    const offsetMinutes = -date.getTimezoneOffset()
    
    // Convert offset to hours and minutes
    const offsetHours = Math.floor(offsetMinutes / 60)
    const offsetMins = offsetMinutes % 60
    const offsetString = `GMT${offsetHours >= 0 ? '+' : ''}${offsetHours.toString().padStart(2, '0')}${offsetMins.toString().padStart(2, '0')}`

    return { date: dateString, offset: offsetString }
}

export const getCountersByDuration = async (flowId, minimuDurationThreshold, startDate) => {
    const startDateFormatted = formatDateWithOffset(startDate)
    // startDateStr is in UTC time zone
    try {
        const response = await apiClientEVENTS.get(`/get_counters_by_duration/${flowId}`, {
            params: {
                threshold: minimuDurationThreshold,
                startDateStr: startDateFormatted.date,
                startDateOffset: startDateFormatted.offset
            },
            ...authHeader()
        })
            
        return response.data
    } catch (error) {
        console.error('Could not fetch counters by duration:', error)
        handleAxiosError(error)
        return {}
    }
}

export async function getCountersByDateRange(flowId, startDate, endDate, minimuDurationThreshold) {
    if (!startDate || !endDate) {
        return {} // Return an empty object when startDate or endDate is null
    }
    const startDateFormatted = formatDateWithOffset(startDate)
    const endDateFormatted = formatDateWithOffset(endDate)

    try {
        const response = await apiClientEVENTS.get(`/get_counters_by_date_range/${flowId}`, {
            params: {
                startDate: startDate,
                endDate: endDate,
                threshold: minimuDurationThreshold,
                startDateOffset: startDateFormatted.offset, 
                endDateOffset: endDateFormatted.offset
            },
            ...authHeader()
        })
        return response.data
    } catch (error) {
        console.error('Could not fetch counters by date range:', error)
        handleAxiosError(error)
        return {}
    }
}


export const getCountersListByDayByDuration = async (flowId, minimuDurationThreshold, startDate) => {
    const startDateFormatted = formatDateWithOffset(startDate)

    try {
        const response = await apiClientEVENTS.get(`/get_counters_list_by_day_by_duration/${flowId}`, {
            params: {
                threshold: minimuDurationThreshold,
                startDateOffset: startDateFormatted.offset,
                startDateStr: startDateFormatted.date,
            },
            ...authHeader()
        })
        return response.data
    } catch (error) {
        console.error('Could not fetch counters list by day by duration:', error)
        handleAxiosError(error)
        return {}
    }
}

export const getCountersListByHourByDuration = async (flowId, minimuDurationThreshold, startDate) => {
    const startDateFormatted = formatDateWithOffset(startDate)

    try {
        const response = await apiClientEVENTS.get(`/get_counters_list_by_hour_by_duration/${flowId}`, {
            params: {
                threshold: minimuDurationThreshold,
                startDateOffset: startDateFormatted.offset,
                startDateStr: startDateFormatted.date,
            },
            ...authHeader()
        })
        return response.data
    } catch (error) {
        console.error('Could not fetch counters list by hour by duration:', error)
        handleAxiosError(error)
        return {}
    }
}


export async function getcountersListByDayByDateRange(flowId, startDate, endDate, minimuDurationThreshold) {
    if (!startDate || !endDate) {
        return {} // Return an empty object when startDate or endDate is null
    }

    const startDateFormatted = formatDateWithOffset(startDate)
    const endDateFormatted = formatDateWithOffset(endDate)

    try {
        const response = await apiClientEVENTS.get(`/get_counters_list_by_day_by_date_range/${flowId}`, {
            params: {
                startDate: startDate,
                endDate: endDate,
                threshold: minimuDurationThreshold,
                startDateOffset: startDateFormatted.offset, 
                endDateOffset: endDateFormatted.offset
            },
            ...authHeader()
        })
        return response.data
    } catch (error) {
        console.error('Could not fetch counters list by day by date range:', error)
        handleAxiosError(error)
        return {}
    }
}

export async function getcountersListByHourByDateRange(flowId, startDate, endDate, minimuDurationThreshold) {
    if (!startDate || !endDate) {
        return {} // Return an empty object when startDate or endDate is null
    }

    const startDateFormatted = formatDateWithOffset(startDate)
    const endDateFormatted = formatDateWithOffset(endDate)

    try {
        const response = await apiClientEVENTS.get(`/get_counters_list_by_hour_by_date_range/${flowId}`, {
            params: {
                startDate: startDate,
                endDate: endDate,
                threshold: minimuDurationThreshold,
                startDateOffset: startDateFormatted.offset, 
                endDateOffset: endDateFormatted.offset
            },
            ...authHeader()
        })
        return response.data
    } catch (error) {
        console.error('Could not fetch counters list by hour by date range:', error)
        handleAxiosError(error)
        return {}
    }
}


// export const getEventListByDuration = async (flowId, minimuDurationThreshold, startDate) => {
//     const startDateFormatted = formatDateWithOffset(startDate)
//     console.log('getEventListByDuration - startDateFormatted:', startDateFormatted)
//     try {
//         const response = await apiClientEVENTS.get(`/get_event_list_by_duration/${flowId}`, {
//             params: {
//                 threshold: minimuDurationThreshold,
//                 startDateOffset: startDateFormatted.offset,
//                 startDateStr: startDateFormatted.date,
//             },
//             ...authHeader()
//         })
//         return response.data
//     } catch (error) {
//         console.error('Could not fetch event list by duration:', error)
//         handleAxiosError(error)
//         return {}
//     }
// }

// export const getIdListByDuration = async (flowId, minimuDurationThreshold, startDate) => {
//     const startDateFormatted = formatDateWithOffset(startDate)
//     console.log('getIdListByDuration - startDateFormatted:', startDateFormatted)
//     try {
//         const response = await apiClientEVENTS.get(`/get_trackid_list_by_duration/${flowId}`, {
//             params: {
//                 threshold: minimuDurationThreshold,
//                 startDateOffset: startDateFormatted.offset,
//                 startDateStr: startDateFormatted.date,
//             },
//             ...authHeader()
//         })
//         return response.data
//     } catch (error) {
//         console.error('Could not fetch id list by duration:', error)
//         handleAxiosError(error)
//         return {}
//     }
// }


// export const getEventListByDateRange = async (flowId, startDate, endDate, minimuDurationThreshold) => {
//     if (!startDate || !endDate) {
//         return {} // Return an empty object when startDate or endDate is null
//     }

//     const startDateFormatted = formatDateWithOffset(startDate)
//     const endDateFormatted = formatDateWithOffset(endDate)

//     try {
//         const response = await apiClientEVENTS.get(`/get_event_list_by_date_range/${flowId}`, {
//             params: {
//                 startDate: startDate,
//                 endDate: endDate,
//                 threshold: minimuDurationThreshold,
//                 startDateOffset: startDateFormatted.offset, 
//                 endDateOffset: endDateFormatted.offset
//             },
//             ...authHeader()
//         })
//         return response.data
//     } catch (error) {
//         console.error('Could not fetch event list by date range:', error)
//         handleAxiosError(error)
//         return {}
//     }
// }

// export const getIdListByDateRange = async (flowId, startDate, endDate, minimuDurationThreshold) => {
//     if (!startDate || !endDate) {
//         return {} // Return an empty object when startDate or endDate is null
//     }

//     const startDateFormatted = formatDateWithOffset(startDate)
//     const endDateFormatted = formatDateWithOffset(endDate)

//     try {
//         const response = await apiClientEVENTS.get(`/get_trackid_list_by_date_range/${flowId}`, {
//             params: {
//                 startDate: startDate,
//                 endDate: endDate,
//                 threshold: minimuDurationThreshold,
//                 startDateOffset: startDateFormatted.offset, 
//                 endDateOffset: endDateFormatted.offset
//             },
//             ...authHeader()
//         })
//         return response.data
//     } catch (error) {
//         console.error('Could not fetch id list by date range:', error)
//         handleAxiosError(error)
//         return {}
//     }
// }

export const getHeatmapByHourByDuration = async (flowId, minimuDurationThreshold, detectionZoneName, startDate, duration) => {
    const startDateFormatted = formatDateWithOffset(startDate)

    try {
        const response = await apiClientEVENTS.get(`/get_heatmap_by_hour_by_duration/${flowId}`, {
            params: {
                threshold: minimuDurationThreshold,
                startDateOffset: startDateFormatted.offset,
                startDateStr: startDateFormatted.date,
                detectionZoneName: detectionZoneName,
                duration: duration
            },
            ...authHeader()
        })
        return response.data
    } catch (error) {
        console.error('Could not fetch heatmap data by hour by duration:', error)
        handleAxiosError(error)
        return {}
    }
}

export async function getHeatmapByHourByDateRange(flowId, startDate, endDate, minimuDurationThreshold, detectionZoneName) {
    if (!startDate || !endDate) {
        return {} // Return an empty object when startDate or endDate is null
    }

    const startDateFormatted = formatDateWithOffset(startDate)
    const endDateFormatted = formatDateWithOffset(endDate)

    try {
        const response = await apiClientEVENTS.get(`/get_heatmap_by_hour_by_date_range/${flowId}`, {
            params: {
                startDate: startDate,
                endDate: endDate,
                threshold: minimuDurationThreshold,
                detectionZoneName: detectionZoneName,
                startDateOffset: startDateFormatted.offset, 
                endDateOffset: endDateFormatted.offset
            },
            ...authHeader()
        })
        return response.data
    } catch (error) {
        console.error('Could not fetch heatmap data by hour by date range:', error)
        handleAxiosError(error)
        return {}
    }
}

export const getFixedDashboardCountersValues = async (flowIdsEntranceZone, flowIdsProductZone, todayDate, startDate7DaysAgo, startDate30DaysAgo, endDateYesterday) => {
    const todayDateFormatted = formatDateWithOffset(todayDate)
    const startDate7DaysAgoFormatted = formatDateWithOffset(startDate7DaysAgo)
    const startDate30DaysAgoFormatted = formatDateWithOffset(startDate30DaysAgo)
    const endDateYesterdayFormatted = formatDateWithOffset(endDateYesterday)

    try {
        const response = await apiClientEVENTS.get(`/get_fixed_dashboard_counters_values/`, {
            params: {
                flowIdsEntranceZone: flowIdsEntranceZone,
                flowIdsProductZone: flowIdsProductZone,
                todayDate: todayDateFormatted.date,
                startDate7DaysAgo: startDate7DaysAgoFormatted.date,
                startDate30DaysAgo: startDate30DaysAgoFormatted.date,
                endDateYesterday: endDateYesterdayFormatted.date,
                allDatesOffset: todayDateFormatted.offset,
            },
            ...authHeader()
        })
        return response.data
    } catch (error) {
        console.error('Error fetching data for fixed dashboard widgets:', error)
        handleAxiosError(error)
        return {}
    }
}

export const getTodaysvs30DayAverage = async (flowIds, todayDate, startDate30DaysAgo, endDateYesterday) => {
    const todayDateFormatted = formatDateWithOffset(todayDate)
    const startDate30DaysAgoFormatted = formatDateWithOffset(startDate30DaysAgo)
    const endDateYesterdayFormatted = formatDateWithOffset(endDateYesterday)

    try {
        const response = await apiClientEVENTS.get(`/get_todays_vs_30_day_average/`, {
            params: {
                flowIds: flowIds,
                todayDate: todayDateFormatted.date,
                startDate30DaysAgo: startDate30DaysAgoFormatted.date,
                endDateYesterday: endDateYesterdayFormatted.date,
                allDatesOffset: todayDateFormatted.offset,
            },
            ...authHeader()
        })
        return response.data
    } catch (error) {
        console.error('Error fetching data comparing today entrance counts with past 30 days average:', error)
        handleAxiosError(error)
        return {}
    }
}

export const sendMessageToAgent = async (userInput) => {
    try{
        const response = await apiClientEVENTS.get(`/send_message_to_agent/`, {
            params: {
                userInput: userInput,
            },
            ...authHeader()
        })
        return response.data

    } catch (error) {
        console.error('Error sending message to data analytics agent:', error)
        handleAxiosError(error)
        return {}
    }
}