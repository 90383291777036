import axios from 'axios'
import { getAPIURL, getAPIURLEvents, getAPIURLAnalytics } from '@/config'

import router from '@/router'

const API_URL = getAPIURL()
const API_URL_EVENTS = getAPIURLEvents()
const API_URL_ANALYTICS = getAPIURLAnalytics()

let isTokenExpiredAlertShown = false

const createApiClient = (baseURL) => {
  const apiClient = axios.create({ baseURL })

  apiClient.interceptors.response.use(
    response => response,
    error => {
      // Check for unauthorized errors
      if (error.response && error.response.status === 401 && !isTokenExpiredAlertShown) {
        // Check if the current route is NOT the login route
        if (router.currentRoute.value.path !== '/login') {
          isTokenExpiredAlertShown = true

          // Import stores within the function scope
          const { useUserStore } = require('@/stores/UserStore')
          const { useCompanyStore } = require('@/stores/CompanyStore')
          const { useFlowStore } = require('@/stores/FlowStore')

          // Clear states in stores
          const userStore = useUserStore()
          const companyStore = useCompanyStore()
          const flowStore = useFlowStore()

          userStore.clearUserState()
          companyStore.clearCompanyState()
          flowStore.clearFlows()

          alert("Your session has expired. You will be redirected to the login page.")
          router.push('/login') // Redirect using router
        }
      }
      return Promise.reject(error)
    }
  )

  return apiClient
}

const apiClientDB = createApiClient(API_URL)
const apiClientEVENTS = createApiClient(API_URL_EVENTS)
const apiClientANALYTICS = createApiClient(API_URL_ANALYTICS)

export { apiClientDB, apiClientEVENTS, apiClientANALYTICS }