import { defineStore } from 'pinia'

import { apiClientDB } from '@/config_sessions'

export const useDetectionZoneTypeStore = defineStore({
    id: 'detectionzonetype',

    state: () => ({
        detectionzonetypes: [],
        loading: false,
        error: null
    }),

    getters: {

    },
    
    actions: {
        async fetchDetectionZoneTypes() {
            this.detectionzonetypes = []
            this.loading = true
            try {
                const response = await apiClientDB.get(`/detectionzonetypes/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })
                this.detectionzonetypes = response.data.detectionzonetypes
            }
            catch (error) {
                this.error = error
            }
            finally {
                this.loading = false
            }
        }
    }
})