import { createRouter, createWebHistory } from 'vue-router'

import Home from '../components/Home.vue'
import CameraOverview from '../components/CameraOverview.vue'
import FlowSettings from '../components/FlowSettings.vue'
import NewFlow from '../components/NewFlow.vue'
import FlowOutput from '../components/FlowOutput.vue'
import APIOverview from '../components/APIOverview.vue'
import NewAPIConnection from '../components/NewAPIConnection.vue'
import QuickDataAnalytics from '../components/QuickDataAnalytics.vue'
import Settings from '../components/Settings.vue'
import Registration from '../components/Registration.vue'
import Login from '../components/Login.vue'

import { useUserStore } from '@/stores/UserStore'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),

  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: { requiresAuth: true }
    },
    {
      path: '/camera_overview',
      name: 'CameraOverview',
      component: CameraOverview,
      meta: { requiresAuth: true }
    },
    {
      path: '/flow/:id',
      name: 'FlowOutput',
      component: FlowOutput,
      meta: { requiresAuth: true }
    },
    {
      path: '/flow_settings/:id',
      name: 'FlowSettings',
      component: FlowSettings,
      meta: { requiresAuth: true }
    },
    {
      path: '/create_flow',
      name: 'NewFlow',
      component: NewFlow,
      meta: { requiresAuth: true }
    },
    {
      path: '/external_data',
      name: 'APIOverview',
      component: APIOverview,
      meta: { requiresAuth: true }
    },
    {
      path: '/connect_api',
      name: 'NewAPIConnection',
      component: NewAPIConnection,
      meta: { requiresAuth: true }
    },
    {
      path: '/data_analytics',
      name: 'QuickDataAnalytics',
      component: QuickDataAnalytics,
      meta: { requiresAuth: true }
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings,
      meta: { requiresAuth: true }
    },
    {
      path: '/registration',
      name: 'Registration',
      component: Registration,
      meta: { isPublic: true }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: { isPublic: true }
    }
  ],

  //Always scroll to top
  scrollBehavior(to, from, savedPosition) {
    
    return { top: 0 }
  },
})

function isAuthenticated() {
  // Check if the token is stored in local storage
  return !!localStorage.getItem('authToken') // Replace 'authToken' with the actual key you use
}

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore()
  const isAuthenticated = !!localStorage.getItem('authToken')
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  // if (!to.meta.isPublic && !isAuthenticated()) {
  if (requiresAuth && !isAuthenticated) {
    alert("You must be logged with the right credentials to view this page.")
    next({ path: '/login' }); // Redirect to login page if not authenticated
  } else {
    if (isAuthenticated && !userStore.user.UserName) {
      // Assuming you have a method in your user store to fetch user details
      await userStore.fetchUserDetails()
    }
    next() // Proceed if the route is public or user is authenticated
  }
})

export default router