import { defineStore } from 'pinia'

import { apiClientDB } from '@/config_sessions'

export const useDetectionZoneStore = defineStore({
    id: 'detectionzones',

    state: () => ({
        detectionzones: [],
        detectionzone: {},
        loading: false,
        error: null
    }),

    getters: {

    },

    actions: {
        async fetchDetectionZones() {
            // Initialize detectionZoneData in local storage if it doesn't exist
            const storedDetectionZones = JSON.parse(localStorage.getItem('detectionZoneData')) || null

            // Check if the detection zones are already cached in local storage
            if (storedDetectionZones) {
                // Set inputcamerastreams from cached data
                this.detectionzones = storedDetectionZones

                return this.detectionzones
            }

            // If not cached, fetch from API
            this.detectionzones = []
            this.loading = true
            try {
                const response = await apiClientDB.get(`/detection_zones/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })
                // Set detectionzones with fetched data
                this.detectionzones = response.data.detectionzones

                // Cache the response data in local storage for future use
                localStorage.setItem('detectionZoneData', JSON.stringify(this.detectionzones))

                return this.detectionzones
            }
            catch (error) {
                this.error = error
            }
            finally {
                this.loading = false
            }
        },

        async fetchDetectionZone(DetectionZoneId) {
            this.detectionzone = {}
            this.loading = true
            try {
                const response = await apiClientDB.get(`/detection_zones/${DetectionZoneId}/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })
                this.detectionzone = response.data  
            }
            catch (error) {
                this.error = error
            }
            finally {
                this.loading = false
            }
        },

        async addDetectionZoneToFlow(FlowId, DetectionZone) {
            try {
                const data = {
                    FlowId,
                    DetectionZone
                }

                await apiClientDB.post(`/detection_zones/`, data, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })

                // Clear or update local storage to ensure fresh data
                localStorage.removeItem('detectionZoneData')
                localStorage.removeItem('FixedWidgetsData')

                await this.fetchDetectionZones()
            }
            catch (error) {
                if (error.response) {
                    alert("There was an error processing your request. You will be redirected to the homepage.")
                    setTimeout(() => {
                        window.location = '/'
                    }, 3000)
                } 
                else {
                    this.error = error
                }
            }
            finally {
                this.loading = false
            }
        },

        async editDetectionZoneToFlow(FlowId, newDetectionZones) {
            try {
                const currentZones = await this.fetchDetectionZonesByFlowId(FlowId)
                const detectionZoneTypes = await this.fetchDetectionZoneTypes()

                // Transform newDetectionZones to an array format
                const transformedNewZones = Object.keys(newDetectionZones).map(key => {
                    const zone = newDetectionZones[key]
                    if (!zone.type || !Array.isArray(zone.points)) {
                        throw new Error(`Invalid data for detection zone: ${key}`)
                    }
                    return {
                        DetectionZoneName: key,
                        DetectionZoneTypeId: detectionZoneTypes.get(zone.type),
                        DetectionZonePointArray: JSON.parse(JSON.stringify(zone.points)), // Ensure it's passed as a deep-cloned array
                        FlowId: FlowId
                    }
                })
                
                let toAdd = []
                let toDelete = []

                // Perform comparison and update if zones exist
                const comparisonResult = this.compareDetectionZones(currentZones, transformedNewZones)
                toAdd = comparisonResult.toAdd || []
                toDelete = comparisonResult.toDelete || []

                // Delete zones
                for (const zoneId of toDelete) {
                    await this.deleteDetectionZone(zoneId)
                }

                // Add new zones
                for (const zone of toAdd) {
                    await this.addDetectionZone(zone)
                }

                console.log('Detection zones updated successfully.')
            } catch (error) {
                console.error("Error updating DetectionZone table:", error)
            }
        },

        async fetchDetectionZoneTypes() {
            const response = await apiClientDB.get('/detectionzonetypes/', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                }
            })
            const typesArray = response.data.detectionzonetypes
            const typesMap = new Map()
            typesArray.forEach(type => {
                typesMap.set(type.DetectionZoneTypeName, type.DetectionZoneTypeId)
            })
            return typesMap
        },

        compareDetectionZones(currentZones, newZones) {
            const toAdd = []
            const toDelete = []

            // Ensure inputs are arrays
            currentZones = currentZones || []
            newZones = newZones || []

            // Create maps of current and new zones based on their type, name, and points
            const currentZonesMap = new Map()
            currentZones.forEach(zone => {
                const parsedPointArray = JSON.parse(zone.DetectionZonePointArray)  // Parse to match newZones format
                const key = `${zone.DetectionZoneTypeId}-${zone.DetectionZoneName}-${JSON.stringify(parsedPointArray)}`
                currentZonesMap.set(key, zone);
            });

            const newZonesMap = new Map()
            newZones.forEach(zone => {
                const key = `${zone.DetectionZoneTypeId}-${zone.DetectionZoneName}-${JSON.stringify(zone.DetectionZonePointArray)}`
                newZonesMap.set(key, zone)
            });

            // Determine zones to delete
            currentZonesMap.forEach((zone, key) => {
                if (!newZonesMap.has(key)) {
                    toDelete.push(zone.DetectionZoneId)
                }
            })

            // Determine zones to add
            newZonesMap.forEach((zone, key) => {
                if (!currentZonesMap.has(key)) {
                    toAdd.push(zone)
                }
            });

            return { toAdd, toDelete }
        },
        
        async fetchDetectionZonesByFlowId(FlowId) {
            const response = await apiClientDB.get(`/detection_zones/flow/${FlowId}/`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                }
            })
            return response.data.detectionZones || []
        },
        
        async addDetectionZone(zone) {
            try {
                // Validate zone data before sending the request
                if (!zone.DetectionZoneName || !zone.DetectionZoneTypeId || !zone.DetectionZonePointArray || !zone.FlowId) {
                    throw new Error('Invalid detection zone data')
                }

                const data = {
                    FlowId: zone.FlowId,
                    DetectionZone: {
                        DetectionZoneName: zone.DetectionZoneName,
                        DetectionZoneTypeId: zone.DetectionZoneTypeId,
                        DetectionZonePointArray: zone.DetectionZonePointArray  // Ensure it's passed as an array
                    }
                }
        
                const response = await apiClientDB.post(`/detection_zones/add`, data, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })
                return response.data
            } catch (error) {
                console.error('Error adding detection zone:', error.response ? error.response.data : error.message)
                throw error // Rethrow error to be caught in the calling function
            }
        },
        
        async deleteDetectionZone(zoneId) {
            const response = await apiClientDB.delete(`/detection_zones/${zoneId}/`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                }
            });
            return response.data
        },


        async editDetectionZone(DetectionZoneId, DetectionZone) {
            try {
                const data = {
                    DetectionZone
                }
                await apiClientDB.put(`/detection_zones/${DetectionZoneId}/`, data, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })
                await this.fetchDetectionZone(DetectionZoneId)
            }
            catch (error) {
                this.error = error
            }
            finally {
                this.loading = false
            }
        },

        clearDetectionZones() {
            console.log('Removing DetectionZoneData')
            this.detectionzones = []
            console.log('this.detectionzones:', this.detectionzones)
            localStorage.removeItem('detectionZoneData')  // Clear from localStorage
            console.log('DetectionZoneData Removed')
        }
    }
})