import { defineStore } from 'pinia'

import axios from 'axios'

import { getAPIURL } from '@/config'
const API_URL = getAPIURL()

export const useCompanyStore = defineStore({
    id: 'company',

    state: () => ({
        company: {
            CompanyId: null,
            CompanyName: null,
            SubscriptionType: null,
            SocketioURLPublic: null,
            SocketioURLLocal: null,
            users: [],
            settings: {}
        },
        loading: false,
        error: null,
    }),

    actions: {
        async fetchCompanyDetails(companyId) {
            this.loading = true
            // If not, fetch from API
            try {
                const response = await axios.get(`${API_URL}/company-details/${companyId}`)
                this.company = response.data
            } catch (error) {
                this.error = error.response.data.message
                throw new Error(error.response.data.message)
            } finally {
                this.loading = false
            }
        },

        async updateCompanyUrl(company) {
            try {
                await axios.put(`${API_URL}/api/company/${company.CompanyId}/url`, { SocketioURLPublic: company.SocketioURLPublic,  SocketioURLLocal: "Null" })
            } catch (error) {
                console.error('Failed to update company URL:', error)
                throw error
            }
        },

        async updateCompanySettings(company) {
            try {
                await axios.put(`${API_URL}/api/company/${company.CompanyId}/settings`, company.settings)
            } catch (error) {
                console.error('Failed to update company settings:', error)
                throw error
            }
        },


        clearCompanyState() {
            this.company = {
                CompanyId: null,
                CompanyName: null,
                SubscriptionType: null,
                SocketioURLPublic: null,
                SocketioURLLocal: null,
                users: [],
                settings: {}
                // reset other relevant properties
            }
            console.log('Company state cleared:', this.company)
            localStorage.removeItem('companyDetails')
        }
    }
})