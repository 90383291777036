import { defineStore } from 'pinia'

import { apiClientDB } from '@/config_sessions'

export const useFlowStore = defineStore({
    id: 'flow',

    state: () => ({
        flows: [],
        currentFlow: {},
        dashboardFlow: {},
        loading: false,
        error: null
    }),

    getters: {
        
    },

    actions: {
        async fetchFlows() {
            this.loading = true
            const storedFlows = localStorage.getItem('listOfFlows')

            // If flows exist in localStorage, use them
            if (storedFlows) {
                this.flows = JSON.parse(storedFlows)
                this.loading = false
                return
            }

            // If not, fetch from API
            try {
                console.log('TOKEN: ', localStorage.getItem('authToken'))
                const response = await apiClientDB.get(`/flows/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })
                this.flows = response.data.flows
                localStorage.setItem('listOfFlows', JSON.stringify(this.flows))  // Save to localStorage
            }
            catch (error) {
                this.error = error
            }
            finally {
                this.loading = false
            }
        },

        async fetchCurrentFlow(FlowId) {
            this.currentFlow = {}
            this.loading = true
            try {
                const response = await apiClientDB.get(`/flows/${FlowId}/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })
                this.currentFlow = response.data
                return response.data
            }
            catch (error) {
                this.handleError(error)
            }
            finally {
                this.loading = false
            }
        },

        async fetchDashboardFlow(FlowId) {
            // Initialize flowDashboardData in local storage if it doesn't exist
            const flowDashboardData = JSON.parse(localStorage.getItem('flowDashboardData')) || {}

            // Check if the FlowId is already cached in local storage
            if (flowDashboardData[FlowId]) {
                // Set dashboardFlow from cached data
                this.dashboardFlow = flowDashboardData[FlowId]

                return this.dashboardFlow
            }

            // If FlowId not cached, fetch from API
            this.dashboardFlow = {}
            this.loading = true
            try {
                const response = await apiClientDB.get(`/dashboardflows/${FlowId}/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })
                // Cache and set the fetched data
                flowDashboardData[FlowId] = response.data
                localStorage.setItem('flowDashboardData', JSON.stringify(flowDashboardData))
                this.dashboardFlow = flowDashboardData[FlowId]

                return response.data
            }
            catch (error) {
                this.handleError(error)
            }
            finally {
                this.loading = false
            }
        },

        async createFlow(FlowName, InputTypeId) {
            try {
                const data = {
                    FlowName,
                    InputTypeId,
                }

                const response = await apiClientDB.post(`/flows/`, data, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })

                const createdFlowId = response.data.FlowId

                // Clear or update local storage to ensure fresh data
                localStorage.removeItem('listOfFlows')
                localStorage.removeItem('FixedWidgetsData')
                localStorage.removeItem('detectionZoneData')

                await this.fetchFlows()
                return createdFlowId
            }
            catch (error) {
                this.error = error
            }
            finally {
                this.loading = false
            }
        },

        async editFlow(FlowId, FlowName, InputTypeId, UserId) {
            try {
                const data = {
                    FlowId,
                    FlowName,
                    InputTypeId,
                    UserId
                }
                await apiClientDB.put(`/flows/${FlowId}/`, data, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })
            }
            catch (error) {
                if (error.response) {
                    alert("There was an error processing your request. You will be redirected to the homepage.")
                    setTimeout(() => {
                        window.location = '/'
                    }, 3000)
                } 
                else {
                    this.error = error
                }
            }
            finally {
                this.loading = false
            }
        },

        async deleteFlow(FlowId) {
            try {
                await apiClientDB.delete(`/flows/${FlowId}/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })

                // Remove the deleted flow from the local state
                this.flows = this.flows.filter(flow => flow.FlowId !== FlowId)
            }
            catch (error) {
                if (error.response) {
                    alert("There was an error processing your request. You will be redirected to the homepage.")
                    setTimeout(() => {
                        window.location = '/'
                    }, 3000)
                } 
                else {
                    this.error = error
                }
            }
            finally {
                this.loading = false
            }
        },

        clearFlows() {
            this.flows = []
            localStorage.removeItem('listOfFlows')  // Clear from localStorage
            localStorage.removeItem('flowDashboardData'); // Clear flowDashboardData cache from localStorage
        }
    }
})