import { defineStore } from 'pinia'

import { apiClientDB } from '@/config_sessions'

export const useAPIWeatherDataStore = defineStore({
    id: 'apiweatherdata',

    state: () => ({
        apiweatherdatarecords: [],
        apiweatherdatarecord: {},
        loading: false,
        error: null
    }),

    getters: {
        
    },

    actions: {
        async fetchAPIWeatherDataRecords() {
            this.apiweatherdatarecords = []
            this.loading = true
            try {
                const response = await apiClientDB.get(`/apiweatherdatarecords/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })
                this.apiweatherdatarecords = response.data.apiweatherdatarecords
            }
            catch (error) {
                this.error = error
            }
            finally {
                this.loading = false
            }
        },

        async fetchAPIWeatherDataRecord(APIWeatherRecordId) {
            this.apiweatherdatarecord = {}
            this.loading = true
            try {
                const response = await apiClientDB.get(`/apiweatherdatarecords/${APIWeatherRecordId}/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })
                this.apiweatherdatarecord = response.data  
            }
            catch (error) {
                if (error.response) {
                    alert("There was an error processing your request.")
                } 
                else {
                    this.error = error
                }
            }
            finally {
                this.loading = false
            }
        },

        async createAPIWeatherDataRecord(APIWeatherRecordName, APITypeName, Latitude, Longitude) {
            try {
                const data = {
                    APIWeatherRecordName,
                    APITypeName,
                    Latitude,
                    Longitude,
                }

                const response = await apiClientDB.post(`/apiweatherdatarecords/`, data, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })

                const createdAPIWeatherDataRecord = response.data.APIWeatherRecordId

                // Clear or update local storage to ensure fresh data
                localStorage.removeItem('listOfFlows')
                localStorage.removeItem('FixedWidgetsData')
                localStorage.removeItem('detectionZoneData')

                await this.fetchFlows()
                return createdAPIWeatherDataRecord
            }
            catch (error) {
                this.error = error
            }
            finally {
                this.loading = false
            }
        },

        async editAPIWeatherDataRecord(APIWeatherRecordId, APIWeatherRecordName, APIId, Latitude, Longitude, CompanyId) {
            try {
                const data = {
                    APIWeatherRecordId,
                    APIWeatherRecordName,
                    APIId,
                    Latitude,
                    Longitude,
                    CompanyId
                }
                await apiClientDB.put(`/apiweatherdatarecords/${APIWeatherRecordId}/`, data, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })
            }
            catch (error) {
                if (error.response) {
                    alert("There was an error processing your request.")
                } 
                else {
                    this.error = error
                }
            }
            finally {
                this.loading = false
            }
        },

        async deleteAPIWeatherDataRecord(APIWeatherRecordId) {
            try {
                await apiClientDB.delete(`/apiweatherdatarecords/${APIWeatherRecordId}/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })

                // Remove the deleted flow from the local state
                this.apiweatherdatarecords = this.apiweatherdatarecords.filter(apiweatherdatarecord => apiweatherdatarecord.APIWeatherRecordId !== APIWeatherRecordId)
            }
            catch (error) {
                if (error.response) {
                    alert("There was an error processing your request.")
                } 
                else {
                    this.error = error
                }
            }
            finally {
                this.loading = false
            }
        },
    }
})