export function getAPIURL() {
    return import.meta.env.VITE_VUE_APP_API_URL
}

export function getAPIURLEvents() {
    return import.meta.env.VITE_VUE_APP_API_URL_EVENTS
}

export function getAPIURLAnalytics() {
    return import.meta.env.VITE_VUE_APP_API_URL_ANALYTICS
}