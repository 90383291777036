import { apiClientANALYTICS } from '@/config_sessions'

import { useToast } from 'vue-toastification'
const toast = useToast()

const authHeader = () => ({
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
    }
})

const handleAxiosError = (error) => {
    if (error.response) {
        const { status, data } = error.response
        const errorMessage = data.message || data.error || 'Unknown error'

        if (status === 401 && errorMessage.includes('Token')) {
            console.error('Token expired. Redirecting to login.');
            toast.error('Token expired. Please log in again.');
            // Redirect to login page
            window.location.href = '/login';
        } else {
            console.error('Server Error:', errorMessage);
            toast.error(`Server Error: ${errorMessage}`);
        }
    } else if (error.request) {
        console.error('Network Error:', error.request)
        toast.error('Network Error. Please try again later.')
    } else {
        console.error('Error:', error.message)
        toast.error(`Error: ${error.message}`)
    }
}

export const previewExternalDataByDuration = async (selectedWeatherDataIds, duration, dataGranularity) => {
    try {
        const response = await apiClientANALYTICS.get(`/preview-dataset-by-duration/`, {
            params: {
                selectedWeatherDataIds: selectedWeatherDataIds,
                duration: duration,
                dataGranularity: dataGranularity,
            },
            ...authHeader()
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('Failed to load external dataset by duration', response.status)
            return null
        }
    } catch (error) {
        console.error('Could not load external dataset by duration:', error)
        handleAxiosError(error)
        return 0 // Return 0 in case of an error
    }
}

export const previewExternalDataByDateRange = async (selectedWeatherDataIds, startDate, endDate, dataGranularity) => {
    try {
        const response = await apiClientANALYTICS.get(`/preview-dataset-by-date-range/`, {
            params: {
                selectedWeatherDataIds: selectedWeatherDataIds,
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                dataGranularity: dataGranularity,
            },
            ...authHeader()
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('Failed to load external dataset by date range', response.status)
            return null
        }
    } catch (error) {
        console.error('Could not load external dataset by date range:', error)
        handleAxiosError(error)
        return 0 // Return 0 in case of an error
    }
}

export const loadConnectedDataByDuration = async (flowId, selectedWeatherDataIds, detectionZoneNames, duration, dataGranularity, minimumDurationThreshold) => {
    try {
        const response = await apiClientANALYTICS.get(`/load-connected-data-by-duration/`, {
            params: {
                flowId: flowId,
                selectedWeatherDataIds: selectedWeatherDataIds,
                detectionZoneNames: detectionZoneNames,
                duration: duration,
                dataGranularity: dataGranularity,
                minimumDurationThreshold: minimumDurationThreshold
            },
            ...authHeader()
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('Failed to load external dataset by duration', response.status)
            return null
        }
    } catch (error) {
        console.error('Could not load external dataset by duration:', error)
        handleAxiosError(error)
        return 0 // Return 0 in case of an error
    }
}

export const loadConnectedDataByDateRange = async (flowId, selectedWeatherDataIds, detectionZoneNames, startDate, endDate, dataGranularity, minimumDurationThreshold) => {
    try {
        const response = await apiClientANALYTICS.get(`/load-connected-data-by-date-range/`, {
            params: {
                flowId: flowId,
                selectedWeatherDataIds: selectedWeatherDataIds,
                detectionZoneNames: detectionZoneNames,
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                dataGranularity: dataGranularity,
                minimumDurationThreshold: minimumDurationThreshold
            },
            ...authHeader()
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('Failed to load external dataset by date range', response.status)
            return null
        }
    } catch (error) {
        console.error('Could not load external dataset by date range:', error)
        handleAxiosError(error)
        return 0 // Return 0 in case of an error
    }
}

export const startSmartAnalyticsStatDescripByDuration = async (flowId, selectedWeatherDataIds, detectionZoneNames, duration, dataGranularity, minimumDurationThreshold) => {
    try {
        const response = await apiClientANALYTICS.get(`/start-smart-analytics_stat_descrip_by_duration/`, {
            params: {
                flowId: flowId,
                selectedWeatherDataIds: selectedWeatherDataIds,
                detectionZoneNames: detectionZoneNames,
                duration: duration,
                dataGranularity: dataGranularity,
                minimumDurationThreshold: minimumDurationThreshold
            },
            timeout: 300000, // 5 minutes in milliseconds
            ...authHeader()
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('Failed to perform descriptive statistics', response.status)
            return null
        }
    } catch (error) {
        console.error('Could not perform descriptive statistics:', error)
        handleAxiosError(error)
        return 0 // Return 0 in case of an error
    }
}

export const startSmartAnalyticsStatDescripByDateRange = async (flowId, selectedWeatherDataIds, detectionZoneNames, startDate, endDate, dataGranularity, minimumDurationThreshold) => {
    try {
        const response = await apiClientANALYTICS.get(`/start-smart-analytics_stat_descrip_by_date_range/`, {
            params: {
                flowId: flowId,
                selectedWeatherDataIds: selectedWeatherDataIds,
                detectionZoneNames: detectionZoneNames,
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                dataGranularity: dataGranularity,
                minimumDurationThreshold: minimumDurationThreshold
            },
            timeout: 300000, // 5 minutes in milliseconds
            ...authHeader()
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('Failed to perform descriptive statistics', response.status)
            return null
        }
    } catch (error) {
        console.error('Could not perform descriptive statistics:', error)
        handleAxiosError(error)
        return 0 // Return 0 in case of an error
    }
}

export const startSmartAnalyticsCorrAnByDuration = async (flowId, selectedWeatherDataIds, detectionZoneNames, duration, dataGranularity, minimumDurationThreshold) => {
    try {
        const response = await apiClientANALYTICS.get(`/start-smart-analytics_corr_an_by_duration/`, {
            params: {
                flowId: flowId,
                selectedWeatherDataIds: selectedWeatherDataIds,
                detectionZoneNames: detectionZoneNames,
                duration: duration,
                dataGranularity: dataGranularity,
                minimumDurationThreshold: minimumDurationThreshold
            },
            timeout: 300000, // 5 minutes in milliseconds
            ...authHeader()
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('Failed to perform correlation analysis', response.status)
            return null
        }
    } catch (error) {
        console.error('Could not perform correlation analysis:', error)
        handleAxiosError(error)
        return 0 // Return 0 in case of an error
    }
}

export const startSmartAnalyticsCorrAnByDateRange = async (flowId, selectedWeatherDataIds, detectionZoneNames, startDate, endDate, dataGranularity, minimumDurationThreshold) => {
    try {
        const response = await apiClientANALYTICS.get(`/start-smart-analytics_corr_an_by_date_range/`, {
            params: {
                flowId: flowId,
                selectedWeatherDataIds: selectedWeatherDataIds,
                detectionZoneNames: detectionZoneNames,
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                dataGranularity: dataGranularity,
                minimumDurationThreshold: minimumDurationThreshold
            },
            timeout: 300000, // 5 minutes in milliseconds
            ...authHeader()
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('Failed to perform correlation analysis', response.status)
            return null
        }
    } catch (error) {
        console.error('Could not perform correlation analysis:', error)
        handleAxiosError(error)
        return 0 // Return 0 in case of an error
    }
}

export const startSmartAnalyticsTrendAnByDuration = async (flowId, selectedWeatherDataIds, detectionZoneNames, duration, dataGranularity, minimumDurationThreshold) => {
    try {
        const response = await apiClientANALYTICS.get(`/start-smart-analytics_trend_an_by_duration/`, {
            params: {
                flowId: flowId,
                selectedWeatherDataIds: selectedWeatherDataIds,
                detectionZoneNames: detectionZoneNames,
                duration: duration,
                dataGranularity: dataGranularity,
                minimumDurationThreshold: minimumDurationThreshold
            },
            timeout: 300000, // 5 minutes in milliseconds
            ...authHeader()
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('Failed to perform trends analysis', response.status)
            return null
        }
    } catch (error) {
        console.error('Could not perform trends analysis:', error)
        handleAxiosError(error)
        return 0 // Return 0 in case of an error
    }
}

export const startSmartAnalyticsTrendAnByDateRange = async (flowId, selectedWeatherDataIds, detectionZoneNames, startDate, endDate, dataGranularity, minimumDurationThreshold) => {
    try {
        const response = await apiClientANALYTICS.get(`/start-smart-analytics_trend_an_by_date_range/`, {
            params: {
                flowId: flowId,
                selectedWeatherDataIds: selectedWeatherDataIds,
                detectionZoneNames: detectionZoneNames,
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                dataGranularity: dataGranularity,
                minimumDurationThreshold: minimumDurationThreshold
            },
            timeout: 300000, // 5 minutes in milliseconds
            ...authHeader()
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('Failed to perform trends analysis', response.status)
            return null
        }
    } catch (error) {
        console.error('Could not perform trends analysis:', error)
        handleAxiosError(error)
        return 0 // Return 0 in case of an error
    }
}

export const startSmartAnalyticsExecSum = async () => {
    try {
        const response = await apiClientANALYTICS.get(`/start-smart-analytics_exec_sum/`, {
            timeout: 300000, // 5 minutes in milliseconds
            ...authHeader()
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('Failed to perform executive summary', response.status)
            return null
        }
    } catch (error) {
        console.error('Could not perform executive summary:', error)
        handleAxiosError(error)
        return 0 // Return 0 in case of an error
    }
}

export const startSmartAnalyticsPrediction = async (flowId, selectedWeatherDataIdsForPrediction, CheckedValuesToPredict, FuturePeriods, dataGranularity, minimumDurationThreshold) => {
    try {
        const response = await apiClientANALYTICS.get(`/start-smart-analytics-predict/${flowId}`, {
            params: {
                selectedWeatherDataIdsForPrediction: selectedWeatherDataIdsForPrediction,
                CheckedValuesToPredict: CheckedValuesToPredict,
                FuturePeriods: FuturePeriods,
                dataGranularity: dataGranularity,
                minimumDurationThreshold: minimumDurationThreshold
            },
            timeout: 480000, // 8 minutes in milliseconds
            ...authHeader()
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('Failed to perform predictions', response.status)
            return null
        }
    } catch (error) {
        console.error('Could not perform predictions:', error)
        handleAxiosError(error)
        return 0 // Return 0 in case of an error
    }
}


//QUICK DATA ANALYTICS STATIC FILE

export const loadFileandGetColumns = async (data_file) => {
    try {
        const response = await apiClientANALYTICS.post(`/load-file-and-get-columns/`, data_file, {
            timeout: 300000, // 5 minutes in milliseconds
            ...authHeader(),
            'Content-Type': 'multipart/form-data'
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('Failed to load file and get columns', response.status)
            return null
        }
    } catch (error) {
        console.error('Could not perform load file and get columns:', error)
        handleAxiosError(error)
        return 0 // Return 0 in case of an error
    }
}

export const startStatisticDataAnalysis = async (primaryFileColumn, dateTimeColumn, dataAnalyticsColumn) => {
    try {
        const response = await apiClientANALYTICS.get(`/start-statistic-data-analysis/`, {
            params: {
                primaryFileColumn: primaryFileColumn,
                dateTimeColumn: dateTimeColumn,
                dataAnalyticsColumn: dataAnalyticsColumn,
            },
            timeout: 300000, // 5 minutes in milliseconds
            ...authHeader(),
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('Failed to perform descriptive statistics', response.status)
            return null
        }
    } catch (error) {
        console.error('Could not perform descriptive statistics:', error)
        handleAxiosError(error)
        return 0 // Return 0 in case of an error
    }
}

export const startCorrelationDataAnalysis = async (correlationColumns) => {
    try {
        const response = await apiClientANALYTICS.get(`/start-statistic-data-analysis_corr_an/`, {
            params: {
                correlationColumns: correlationColumns,
            },
            timeout: 300000, // 5 minutes in milliseconds
            ...authHeader(),
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('Failed to perform correlation analysis', response.status)
            return null
        }
    } catch (error) {
        console.error('Could not perform correlation analysis:', error)
        handleAxiosError(error)
        return 0 // Return 0 in case of an error
    }
}

export const startTrendDataAnalysis = async (timeSeriesColumn, trendAnalysisColumns) => {
    try {
        const response = await apiClientANALYTICS.get(`/start-statistic-data-analysis_trend_an/`, {
            params: {
                timeSeriesColumn: timeSeriesColumn,
                trendAnalysisColumns: trendAnalysisColumns,
            },
            timeout: 300000, // 5 minutes in milliseconds
            ...authHeader(),
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('Failed to perform trend analysis', response.status)
            return null
        }
    } catch (error) {
        console.error('Could not perform trend analysis:', error)
        handleAxiosError(error)
        return 0 // Return 0 in case of an error
    }
}

export const startForecastingDataAnalysis = async (timeSeriesColumn, forecastColumns, FuturePeriods, PeriodGranularity) => {
    try {
        const response = await apiClientANALYTICS.get(`/start-statistic-data-analysis-predict/`, {
            params: {
                timeSeriesColumn: timeSeriesColumn,
                forecastColumns: forecastColumns,
                FuturePeriods: FuturePeriods,
                PeriodGranularity: PeriodGranularity,
            },
            timeout: 480000, // 8 minutes in milliseconds
            ...authHeader(),
        })
        if (response.status === 200) {
            return response.data
        } else {
            console.error('Failed to perform forecasting', response.status)
            return null
        }
    } catch (error) {
        console.error('Could not perform forecasting:', error)
        handleAxiosError(error)
        return 0 // Return 0 in case of an error
    }
}