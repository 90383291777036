export const detection_zone_colors_by_name = {
    'Entrance Zone': 'rgba(54, 145, 13, 0.5)', // #36910d with 50% transparency
    'Exit Zone': 'rgba(194, 21, 0, 0.5)', // #C21500 with 50% transparency
    'Combined Entrance Exit Zone': 'rgba(221, 135, 37, 0.5)', // #dd8725 with 50% transparency
    'Checkout Waiting Zone': 'rgba(173, 57, 53, 0.5)', // #AD3935 with 50% transparency
    'Checkout Interaction Zone': 'rgba(211, 167, 65, 0.5)', // #D3A741 with 50% transparency
    'Product Zone': 'rgba(0, 79, 110, 0.5)', // #004F6E with 50% transparency
    'Demo Zone': 'rgba(98, 181, 217, 0.5)', // #62B5D9 with 50% transparency
    'Display Zone': 'rgba(17, 13, 147, 0.5)' // #110D93 with 50% transparency
}

export const detection_zone_colors_by_name_solid = {
    'Entrance Zone': 'rgb(54, 145, 13)', // #36910d
    'Exit Zone': 'rgb(194, 21, 0)', // #C21500
    'Combined Entrance Exit Zone': 'rgb(221, 135, 37)', // #dd8725
    'Checkout Waiting Zone': 'rgb(173, 57, 53)', // #AD3935
    'Checkout Interaction Zone': 'rgb(211, 167, 65)', // #D3A741
    'Product Zone': 'rgb(0, 79, 110)', // #004F6E
    'Demo Zone': 'rgb(98, 181, 217)', // #62B5D9
    'Display Zone': 'rgb(17, 13, 147)' // #110D93
}

export const detection_zone_colors_by_typeid = {
    1: 'rgba(54, 145, 13, 0.5)', // #36910d with 50% transparency
    2: 'rgba(194, 21, 0, 0.5)', // #C21500 with 50% transparency
    3: 'rgba(221, 135, 37, 0.5)', // #dd8725 with 50% transparency
    4: 'rgba(173, 57, 53, 0.5)', // #AD3935 with 50% transparency
    5: 'rgba(211, 167, 65, 0.5)', // #D3A741 with 50% transparency
    6: 'rgba(0, 79, 110, 0.5)', // #004F6E with 50% transparency
    7: 'rgba(98, 181, 217, 0.5)', // #62B5D9 with 50% transparency
    8: 'rgba(17, 13, 147, 0.5)' // #110D93 with 50% transparency
}