import { defineStore } from 'pinia'

import axios from 'axios'

import { getAPIURL } from '@/config'
import { useCompanyStore } from '@/stores/CompanyStore'
import { useFlowStore } from '@/stores/FlowStore'
import { useCameraStreamStore } from '@/stores/CameraStreamStore'
import { useDetectionZoneStore } from '@/stores/DetectionZone'


const API_URL = getAPIURL()

export const useUserStore = defineStore({
    id: 'user',

    state: () => ({
        users: [],
        user: {
            UserId: null,
            UserName: null,
            UserSurname: null,
            UserEmail: null,
            UserRole: null,
            CompanyId: null,
        },
        error: null,
        token: localStorage.getItem('authToken') || null,
        loadingUserDetails: false,

    }),

    getters: {
        isUserLoggedIn: (state) => {
            return !!state.token; // Ensure token is present
        }
    },

    actions: {
        async addUser(userData) {
            try {
                const response = await axios.post(`${API_URL}/add_user`, userData)
                return response.data
            }
            catch (error) {
                this.error = error.response.data.message // Capture the error message
                throw new Error(error.response.data.message) // Throw it as part of the error
            }
        },

        async updateUser(user) {
            console.log(user)
            console.log(user.UserId)
            try {
                await axios.put(`${API_URL}/users/${user.UserId}`, user, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })
            } catch (error) {
                throw new Error('Failed to update user')
            }
        },

        async deleteUser(userEmail) {
            try {
                const response = await axios.delete(`${API_URL}/delete_user`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    data: { userEmail }
                })
                return response.data
            } catch (error) {
                this.error = error.response?.data?.message || 'An error occurred'; // Capture the error message
                throw new Error(this.error) // Throw it as part of the error
            }
        },

        async loginUser(credentials) {
            console.log('Attempting login with credentials')
            try {
                const response = await axios.post(`${API_URL}/login`, credentials)
                this.token = response.data.token; // Store the received token
                console.log('Login successful, token received:', this.token)
                localStorage.setItem('authToken', this.token); // Store token in local storage
                await this.fetchUserDetails()
            } catch (error) {
                console.error('Login failed:', error)
                throw error
            }
        },

        async fetchUserDetails() {
            console.log('Checking if user details are stored in browser memory...')
            const flowStore = useFlowStore()
            const companyStore = useCompanyStore()
            const cameraStreamStore = useCameraStreamStore()
            const detectionZoneStore = useDetectionZoneStore()
        
            // Check if token is present before proceeding
            const token = localStorage.getItem('authToken')
            if (!token) {
                console.log('No token found. Redirecting to login...')
                localStorage.removeItem('FixedWidgetsData')
                detectionZoneStore.clearDetectionZones()
                cameraStreamStore.clearStreams()
                flowStore.clearFlows()
                this.clearUserState()
                companyStore.clearCompanyState()
                return null
            }

            // Check if user details are stored in localStorage
            const storedUser = localStorage.getItem('userDetails')
            
            // If user exists in localStorage, use them
            if (storedUser) {
                console.log('User details found in localStorage')
                this.user = JSON.parse(storedUser); // Load user details from localStorage

                return this.user // Return the stored user details
            }
        
            // If user details are not present in localStorage, make an API call to fetch them
            this.loadingUserDetails = true
            try {
                const token = localStorage.getItem('authToken')
                console.log('Token found in local storage:', token)
        
                // Perform the API call to fetch user details
                const response = await axios.get(`${API_URL}/get_user_details`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
        
                // Update the store and localStorage with the fetched user details
                this.user = response.data
                localStorage.setItem('userDetails', JSON.stringify(this.user)) // Save user details in localStorage
                console.log('User details fetched successfully and saved in localStorage:', this.user)
        
                return this.user // Return the user details after they are fetched
            } catch (error) {
                console.error('Fetching user details failed:', error)
        
                if (error.response && error.response.status === 401) {
                    console.error('Unauthorized access - token expired or invalid')
                    localStorage.removeItem('FixedWidgetsData')
                    detectionZoneStore.clearDetectionZones()
                    cameraStreamStore.clearStreams()
                    flowStore.clearFlows()
                    this.clearUserState()
                    companyStore.clearCompanyState()
                    router.push('/login')
                }
        
                return null // Return null if fetching user details failed
            } finally {
                this.loadingUserDetails = false
            }
        },

        clearUserState() {
            console.log('Clearing user state...')
            this.user = {
                UserId: null,
                UserName: '',
                UserSurname: '',
                UserRole: '',
                UserEmail: '',
                CompanyId: null
                // reset other relevant properties
            }
            localStorage.removeItem('userDetails')
            this.token = null; // Clear the token
            localStorage.removeItem('authToken')
            console.log('User state cleared:', this.user)
        }
    },

    // Watch token change to ensure reactivity
    watch: {
        token(newToken) {
            console.log('Token changed:', newToken)
            if (newToken) {
                localStorage.setItem('authToken', newToken)
            } else {
                localStorage.removeItem('authToken')
            }
        }
    }
})