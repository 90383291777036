import { defineStore } from 'pinia'

import { apiClientDB } from '@/config_sessions'

export const useInputTypeStore = defineStore({
    id: 'inputtype',

    state: () => ({
        inputtypes: [],
        loading: false,
        error: null
    }),

    getters: {

    },
    
    actions: {
        async fetchInputTypes() {
            this.inputtypes = []
            this.loading = true
            try {
                const response = await apiClientDB.get(`/inputtypes/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                })
                this.inputtypes = response.data.inputtypes
            }
            catch (error) {
                this.error = error
            }
            finally {
                this.loading = false
            }
        }
    }
})